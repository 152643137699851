import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	position: fixed;
	z-index: 999;
	left: 0;
	bottom: 0;
	background-color: #fff;
	display: flex;
	justify-content: center;
	cursor: pointer;

	.image {
		width: 25%;
	}

	.close {
		position: absolute;
		top: 50%;
		right: 1em;
		transform: translateY(-50%);
		cursor: pointer;

		.close-icon {
			width: 2em;
			filter: invert(25%) sepia(68%) saturate(2482%) hue-rotate(336deg)
				brightness(94%) contrast(84%);
		}

		&:hover {
			opacity: 0.8;
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
		justify-content: flex-start;

		.image {
			width: 100%;
			margin: 0;
			padding: 0 2.8em 0 0;
		}

		.close {
			top: 51%;
			right: 0.4em;
			transform: translateY(-50%);
			.close-icon {
				width: 2.5em;
			}
		}
	}
`;
